import React, { useEffect, useState } from "react";
import { useGetImportQueue } from "hooks/useGetImportQueue";
import PauseIcon from '../utils/iconComponents/PauseIcon';
import PlayIcon from '../utils/iconComponents/PlayIcon';
import StopIcon from '../utils/iconComponents/StopIcon';
import DownloadIcon from '../utils/iconComponents/DownloadIcon';

import {
  AmazonImportTask,
  useImportTasksProgressSubscription,
} from "hooks/useImportTasksProgressSubscription";
import { Scrollbar } from "react-scrollbars-custom";
import { useChangeAmazonClassificationImportStatus } from "hooks/useChangeAmazonClassificationImportStatus";
import { downloadAsins } from "./downloadAsins";

interface ImportQueueProps {
  onNewImport?: () => void;
}

export const ImportQueue: React.FC<ImportQueueProps> = ({ onNewImport }) => {
  const { getImportQueue, data, loading } = useGetImportQueue();
  const [tasks, setTasks] = useState<AmazonImportTask[]>([]);
  const { changeAmazonClassificationImport, loading: changingTaskStatus } =
    useChangeAmazonClassificationImportStatus();

  const handleDownloadAsins = async (taskId: string) => {
    downloadAsins(taskId, tasks)
      .catch((error) => {
        console.error("Error downloading ASINs:", error);
      });
  };

  useEffect(() => {
    getImportQueue();
  }, []);

  useEffect(() => {
    if (data?.getImportQueue?.tasks) {
      setTasks(data.getImportQueue.tasks);
    }
  }, [data]);

  useImportTasksProgressSubscription((updatedTasks) => {
    setTasks((currentTasks) => {
      const tasksMap = new Map<string, AmazonImportTask>();

      currentTasks.forEach((task) => {
        tasksMap.set(task.id, task);
      });

      updatedTasks.forEach((task) => {
        tasksMap.set(task.id, task);
      });

      return Array.from(tasksMap.values());
    });
  });

  const handleChangeTaskStatus = async (taskId: string, status: string) => {
    try {
      await changeAmazonClassificationImport({
        variables: {
          taskId,
          status,
        },
      });
    } catch (error) {
      console.error("Error changing task status:", error);
    }
  }

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4 flex justify-between items-center border-b border-gray-200">
        <h2 className="text-lg font-semibold text-gray-900">
          Cola de Importación
        </h2>
        <button
          onClick={onNewImport}
          className="inline-flex items-center px-4 py-2 rounded-lg bg-primary-600 text-white hover:bg-primary-700 transition-colors"
        >
          Nueva Importación
        </button>
      </div>

      <div className="p-6">
        {loading && tasks.length === 0
          ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600">
              </div>
            </div>
          )
          : tasks.length === 0
            ? (
              <div className="text-center py-8 text-gray-500">
                No hay importaciones en cola
              </div>
            )
            : (
              <div>
                <div className="grid grid-cols-[1fr_200px_180px_120px_96px] gap-4 mb-4 px-4 py-2 bg-gray-50 rounded-t-lg font-medium text-sm text-gray-600">
                  <div>Categoría</div>
                  <div className="text-center w-[200px]">Progreso</div>
                  <div className="text-center w-[180px]">Creado</div>
                  <div className="text-center w-[120px]">Estado</div>
                  <div className="text-center w-[96px]">Acciones</div>
                </div>

                <div className="h-[calc(100vh-480px)]">
                  <Scrollbar
                    style={{ width: "100%", height: "100%" }}
                    trackYProps={{
                      style: {
                        width: "8px",
                        background: "#f1f1f1",
                        borderRadius: "4px",
                        marginTop: "-10px",
                        height: "100%",
                        padding: 0,
                      },
                    }}
                    thumbYProps={{
                      style: {
                        background: "#c1c1c1",
                        borderRadius: "4px",
                      },
                    }}
                  >
                    {tasks.map((task) => (
                      <div
                        key={task.id}
                        className="grid grid-cols-[1fr_200px_180px_120px_96px] gap-4 items-center border-b border-gray-100"
                      >
                        <div className="text-sm text-gray-500 p-4">
                          {task.classification_name}
                        </div>
                        <div className="text-sm text-gray-500 flex justify-center w-[200px]">
                          {task.uploaded_products < task.quantity ? (
                            <div className="space-y-1 m-2">
                              <ProgressBar
                                uploaded={task.uploaded_products}
                                failed={task.failed_products}
                                total={task.quantity}
                                isCompleted={task.status === "completed"}
                                tooltip={task.status === "completed" ? `Subidos ${task.uploaded_products} de ${task.quantity} productos` : `Subidos ${task.uploaded_products} con un ${Math.floor((task.uploaded_products + task.failed_products) / task.quantity * 100)}% recorridos`}
                              />
                            </div>
                          ) : (
                            <div className="space-y-1 m-2">
                              <ProgressBar
                                uploaded={task.uploaded_products}
                                failed={task.failed_products}
                                total={task.quantity}
                                isCompleted={true}
                                tooltip={`Subidos ${task.uploaded_products} de ${task.quantity} productos`}
                              />
                            </div>
                          )}
                        </div>
                        <div className="text-sm text-gray-500 text-center w-[180px]">
                          {new Date(parseInt(task.created_at)).toLocaleString(undefined, {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false,
                          }).replace(',', '')}
                        </div>
                        <div className="flex justify-center w-[120px]">
                          <StatusBadge status={task.status} />
                        </div>
                        <div className="flex justify-center w-[96px]">
                            <>
                              <button
                                className={`text-xs font-medium rounded flex items-center gap-1 ${['completed','created','failed','paused', 'cancelled'].includes(task.status) ? "bg-gray-50 text-gray-700 border-gray-100" : "text-primary-700 cursor-pointer"}`}
                                onClick={() => handleChangeTaskStatus(task.id, "cancelled")}
                                disabled={changingTaskStatus || ['completed','failed','cancelled'].includes(task.status)}
                              >
                                <StopIcon title="Cancelar" className={['created','paused','processing'].includes(task.status) ? "text-primary-700 cursor-pointer" : "text-gray-500"} />
                              </button>
                              <button
                                className={`text-xs font-medium rounded flex items-center gap-1 ${['completed','created','failed','paused', 'cancelled'].includes(task.status) ? "bg-gray-50 text-gray-500 border-gray-100" : "text-primary-700 cursor-pointer"}`}
                                onClick={() => { handleChangeTaskStatus(task.id, task.status === "processing" ? "paused" : "processing") }}
                                disabled={changingTaskStatus || ['completed','failed','cancelled'].includes(task.status)}
                              >
                                {task.status === "processing" ? <PauseIcon title="Pausar" className="text-primary-700 cursor-pointer" /> : <PlayIcon title="Reanudar" className={task.status === "paused" ? "text-primary-700 cursor-pointer" : "text-gray-500 "} />}
                              </button>
                              <button
                                onClick={() => handleDownloadAsins(task.id)}
                                className={`text-xs font-medium rounded flex items-center gap-1 ${['processing','paused'].includes(task.status) ? "bg-gray-50 text-gray-500 border-gray-100" : "text-primary-700 cursor-pointer"}`}
                                disabled={changingTaskStatus || ['processing', 'paused'].includes(task.status)}
                              >
                                <DownloadIcon className={['completed', 'failed', 'cancelled'].includes(task.status) ? "text-primary-700 cursor-pointer" : "text-gray-500"} title="Descargar ASINs" />
                              </button>
                            </>
                        </div>
                      </div>
                    ))}
                  </Scrollbar>
                </div>
              </div>
            )}
      </div>
    </div>
  );
};

const StatusBadge: React.FC<{ status: string }> = ({ status }) => {
  const statusStyles = {
    created: "bg-primary-50 text-primary-700 border-primary-200",
    processing: "bg-yellow-50 text-yellow-700 border-yellow-100",
    completed: "bg-green-50 text-green-700 border-green-100",
    failed: "bg-red-200 text-red-700 border-red-200",
    paused: "bg-gray-50 text-gray-700 border-gray-100",
    cancelled: "bg-gray-50 text-gray-700 border-gray-100",
  };

  const statusText = {
    created: "En cola",
    processing: "Procesando",
    completed: "Completado",
    failed: "Error",
    paused: "Pausado",
    cancelled: "Cancelado",
  };

  return (
    <span
      className={`px-2 py-1 text-xs font-medium rounded-full border ${statusStyles[status as keyof typeof statusStyles]
        }`}
    >
      {statusText[status as keyof typeof statusText]}
    </span>
  );
};

const ProgressBar: React.FC<{
  uploaded: number;
  failed: number;
  total: number;
  isCompleted?: boolean;
  tooltip?: string;
}> = ({ uploaded, failed, total, isCompleted = false, tooltip }) => {
  const uploadedPercentage = (uploaded / total) * 100;
  const failedPercentage = (failed / total) * 100;

  return (
  <div className="w-[160px] h-6 bg-white rounded overflow-hidden border border-gray-500 relative" title={tooltip || ""}>
      <div className="flex h-full">
        <div
          className="h-full bg-green-500 flex items-center justify-center text-xs text-white"
          style={{ width: `${uploadedPercentage}%` }}
        />
        <div
          className="h-full bg-gray-500 flex items-center justify-center text-xs text-white"
          style={{ width: `${failedPercentage}%` }}
        />
        <div
          className="absolute inset-0 flex items-center justify-center text-xs text-black"
        >
          {uploaded + failed === total ? total : `${uploaded + failed} / ${total}`}
        </div>
      </div>
    </div>
  );
};
