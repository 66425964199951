import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React, { useEffect } from 'react';
import { AoTextInputProps } from '../types/MLForm.types';
import { Control, Controller } from 'react-hook-form';

function AoComboSelect({
  opts,
  label,
  config,
  attributes,
  value,
  catalog_listing = false
}: AoTextInputProps) {
  const [selectedOption, setSelectedOption] = React.useState();
  const id = attributes.id;
  const isRequired = attributes?.tags?.includes('required');
  const options = attributes?.values?.map((val: any) => ({
    label: val.name,
    value: val.id,
  }));
  let onChangeField: any = null;

  const valueType = attributes.value_type;

  if (
    !options.some(
      (opt: any) => opt.label === value || opt.id === value || /\d+/.test(value)
    ) &&
    value
  ) {
    options.push({
      label: value,
      value,
    });
  }

  // useEffect(() => {
  //   if(options) {
  //     setSelectedOption(options.find((opt: any) => attributes.value_type === "boolean" ? opt.label === value : opt.value === value)?.label)
  //   }
  // }, [selectedOption])

  useEffect(() => {
    if (options) {
      // debugger;
      const newValue = options.find(
        (opt: any) => opt.label === value || opt.value === value
      );
      setSelectedOption(newValue);
      // if(onChangeField && newValue) onChangeField(newValue.value);
      if (onChangeField && newValue) onChangeField(newValue.label);
    }
  }, []);

  const control: Control<any> = opts?.control ? opts.control : null;

  const customStyles = {
    control: (provided: any) => ({
      // class attribute : class=" css-i32vvf-control"
      ...provided,
      boxShadow:
        '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
      borderColor: '#d1d5db',
      borderRadius: '0.5rem',
      color: '#101828',
      with: '100%',
      focusOutline: 'none',
      focusShadow: 'outline',
    }),
    menu: (provided: any) => ({
      // 'menu' is from the div class too.
      ...provided,
      width: '100%',
    }),
  };

  return (
    <div className='my-2 grow-0 md:basis-1/3 lg:basis-1/4 px-1'>
      <label className='block text-gray-700 text-sm font-medium mb-2 size-3.5 truncate' htmlFor={id} title={label}>
        {label + (valueType === 'boolean' ? ' (Sí/No)' : '')}
        {isRequired && <span className='text-red-700'>*</span>}
      </label>

      <Controller
        control={control}
        name={id}
        rules={{ required: isRequired }}
        render={({
          field: { onChange, onBlur, ref },
          fieldState: { error },
        }) => {
          onChangeField = onChange;

          return (
            <>
              {config?.allow_custom_value || true ? ( // TODO: check if this is correct for all cases
                <CreatableSelect
                  ref={ref}
                  formatCreateLabel={(inputValue: string) =>
                    `Agregar "${inputValue}"`
                  }
                  onChange={(newValue: any) => {
                    if (newValue) {
                      const option = options.find(
                        (opt: any) => opt.label === newValue?.label
                      );
                      setSelectedOption(option);
                      // onChange(newValue);
                      onChange(newValue);
                    }
                  }}
                  onBlur={onBlur}
                  value={selectedOption}
                  inputId={id}
                  placeholder={'Seleccionar'}
                  options={options}
                  isClearable
                  styles={customStyles}
                  isDisabled = {!catalog_listing ? false : true}
                />
              ) : (
                <Select
                  isDisabled = {!catalog_listing ? false : true}
                  ref={ref}
                  onChange={(newValue) => {
                    if (newValue) {
                      setSelectedOption(
                        options.find((opt: any) => opt.value === newValue)
                      );
                      onChange(newValue);
                    }
                  }}
                  onBlur={onBlur}
                  value={selectedOption}
                  inputId={id}
                  placeholder={'Seleccione'}
                  options={options}
                  isClearable
                  styles={customStyles}
                />
              )}
              {error && (
                <p className='text-red-500 text-xs italic'>
                  Seleccione {label}
                </p>
              )}
            </>
          );
        }}
      />

      <div className='text-gray-500 text-xs truncate' title={config?.hint || config?.tooltip}>
        {config?.hint || config?.tooltip}
      </div>
    </div>
  );
}

export { AoComboSelect };
