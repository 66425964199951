const { useManualQuery } = require('graphql-hooks');

const searchCancelledOrderQuery = `query SearchCancelledOrders($item:String){
  searchCancelledOrders(item:$item){
    orders {
      status
      currency_id
      cancel_detail {
        description
        date
      }
    }
  }
}`;

const useSearchCancelledOrderManual = () => {
  const [searchCancelledOrders, { loading, error, data }] = useManualQuery(
    searchCancelledOrderQuery
  );

  const { orders } = data ? data.searchCancelledOrders : { orders: [] };

  return { searchCancelledOrders, loading, orders };
};

export { useSearchCancelledOrderManual, searchCancelledOrderQuery };
