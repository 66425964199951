import { useQuery, useManualQuery } from 'graphql-hooks';

const GetElegibleCatalogSuggestionsQuery = `
    query GetElegibleCatalogSuggestions($id: String!) {
        getElegibleCatalogSuggestions(id: $id) {
            id
            name
            attributes {
                id
                name
                value_name
            }
            pictures {
                url
            }
        }
    }
`

export const useGetElegibleCatalogSuggestions = (id) => {
    const { loading, error, data } = useQuery(GetElegibleCatalogSuggestionsQuery, {
        variables: { id }
    });
    return { loading, error, data }; 
}

export const useGetElegibleCatalogSuggestionsManual = () => {
    const [getElegibleCatalogSuggestions, { loading, error, data }] = useManualQuery(GetElegibleCatalogSuggestionsQuery);
    return { getElegibleCatalogSuggestions, loading, error, data };
}

export {GetElegibleCatalogSuggestionsQuery}