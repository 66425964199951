import { useManualQuery } from 'graphql-hooks';
export const GET_IMPORT_QUEUE_QUERY = `
  query GetImportQueue {
    getImportQueue {
      tasks {
        id
        status
        classification_id
        classification_name
        quantity
        created_at
        updated_at
        uploaded_products
        failed_products
      }
    }
  }
`;

export const useGetImportQueue = () => {
  const [getImportQueue, { loading, error, data }] = useManualQuery(GET_IMPORT_QUEUE_QUERY);

  return {
    getImportQueue,
    loading,
    error,
    data,
  };
}; 