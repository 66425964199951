import axios from "axios";
import { AmazonImportTask } from "hooks/useImportTasksProgressSubscription";

export const downloadAsins = async (taskId: string, tasks: AmazonImportTask[]) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/import-queue-asins/${taskId}.xlsx`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage?.session)?.sessionInfo?.token}`,
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        responseType: 'blob'
      }
    );

    const blob = response.data;
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    const classificationName = tasks.find((task) => task.id === taskId)?.classification_name;
    const currentTime = new Date().toLocaleString().replace(/[/:]/g, "-");
    a.download = `asins-${classificationName}-${currentTime}.xlsx`;
    
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 100);
  } catch (error) {
    console.error("Error downloading ASINs:", error);
    throw error;
  }
}; 