const { useManualQuery } = require('graphql-hooks');

const searOrdersQuery = `query SearchOrders($query: String!, $offset: Int!, $limit: Int!) {
    searchOrders(query: $query, offset: $offset, limit: $limit) {
      offset
      total
      orders {
        item {
          title
          id
          category_id
          condition
          seller_custom_field
          thumbnail
          permalink
          amazonUrl
        }
        buyer {
            id
            nickname
        }
        id
        order_id
        pack_id
        seller_id
        unit_price
        currency_id
        quantity
        lastUpdated
        dateCreated
        total_amount
        status
        productInfo {
          start_time
          permalink
          sold_quantity
          available_quantity
          catalog_product_id
          seller_custom_field
          id
          title
          category_id
          status
          catalog_listing
          descriptions
          description
          categoryPath
          pictures {
            id
            url
            secure_url
            size
            max_size
            quality
            link
            variant
            width
            height
            id
          }
          shipping {
            mode
            dimensions
            local_pick_up
            free_shipping
            methods
            tags
          }
          attributes {
            name
            value
          }
          tags
          price
          currency_id
          item_relations {
            id
            variation_id
            stock_relation
          }
        }
      }
    }
  }`;

const useSearchOrdersManual = () => {
  const [searchOrders, { loading, error, data }] =
    useManualQuery(searOrdersQuery);

  const { orders, offset, total } = data
    ? data.searchOrders
    : { orders: [], offset: 0, total: 0 };

  return { searchOrders, loading, error, offset, total, orders };
};

export { useSearchOrdersManual };
