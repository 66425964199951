import { useQuery, useManualQuery } from 'graphql-hooks';

export const getItemInfoQuery = `
query GetItemInfo($itemId: String, $storeId: String) {
    getItemInfoQuery(itemId: $itemId, storeId: $storeId) {
      id
      title
      price
      currency_id
      seller_custom_field
      description
      pictures {
        variant
        link
        id
      }
      categoryPath
      attributes {
        name
        value
      }
      catalog_listing
      sold_quantity
      available_quantity
      permalink
      catalog_product_id
    }
  }  
`;

export const useGetItemInfo = (itemId) => {
  const { loading, error, data } = useQuery(getItemInfoQuery, {
    variables: { itemId },
  });
  return { loading, error, data };
};

export const useGetItemInfoManual = () => {
  const [getItemInfo, { loading, error, data }] =
    useManualQuery(getItemInfoQuery);
  return { getItemInfo, loading, error, data };
};

