import { useManualQuery } from "graphql-hooks";

const CHANGE_AMAZON_CLASSIFICATION_IMPORT_STATUS = `
  mutation CancelAmazonClassificationImportStatus($taskId: String!, $status: String!) {
    changeAmazonClassificationImportStatus(taskId: $taskId, status: $status) {
      success
    }
  }
`;

export const useChangeAmazonClassificationImportStatus = () => {
  const [changeAmazonClassificationImport, { loading, error, data }] =
    useManualQuery(CHANGE_AMAZON_CLASSIFICATION_IMPORT_STATUS);

  return {
    changeAmazonClassificationImport,
    loading,
    error,
    data,
  };
};
