import React, { useState } from 'react';
import OrderList from './OrderList';
import OrderDetail from './OrderDetail';
import OrderLabel from './OrderLabel';
import OrderMessage from './OrderMessage';

const OrderPage = () => {
  const [orderInfo, setOrderInfo] = useState();
  const [selectedLabel, setSelectedLabel] = useState();

  const handlerLabelAnswer = (label) => {
    setSelectedLabel(label);
  };

  return (
    <div
      className='inline-flex w-full pt-7'
      style={{ height: 'calc(100vh - 50px)' }}
    >
      <div className='w-[45%] h-full pr-4'>
        <OrderList onOrderSelected={(orderSelected) => setOrderInfo(orderSelected)  } />
      </div>
      <div className='w-[55%] h-full'>
        <div className='h-[14%]'>
          <div className='h-full'>
            <OrderDetail orderInfo={orderInfo} />
          </div>
        </div>
        <div className='h-[14%]'>
          <div className='h-full'>
            <OrderLabel
              handlerLabelAnswer={handlerLabelAnswer}
              orderInfo={orderInfo}
            />
          </div>
        </div>
        <div className='h-[72%]'>
          <div className='h-full'>
              <OrderMessage
                orderInfo={orderInfo}
                selectedLabel={selectedLabel}
                setSelectedLabel={setSelectedLabel}
              />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
