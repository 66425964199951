import React, { useState } from 'react';
import DownloadIcon from '../utils/iconComponents/DownloadIcon';
import InvoiceIcon from '../utils/iconComponents/InvoiceIcon';
import CheckIcon from '../utils/iconComponents/CheckIcon';

export interface Invoice {
  id: string;
  date: string;
  amount: number;
  plan: string;
  status: 'Pago' | 'Pendiente';
}

interface InvoiceListProps {
  invoices: Invoice[];
}

export const InvoiceList: React.FC<InvoiceListProps> = ({ invoices }) => {
  const [selectedInvoices, setSelectedInvoices] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedInvoices(invoices.map(invoice => invoice.id));
      setAllSelected(true);
    } else {
      setSelectedInvoices([]);
      setAllSelected(false);
    }
  };

  const handleSelectInvoice = (id: string) => {
    setSelectedInvoices(prev => {
      const newSelected = prev.includes(id) ? prev.filter(i => i !== id) : [...prev, id];
      setAllSelected(newSelected.length === invoices.length);
      return newSelected;
    });
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Recibos</h2>
        <button 
          className={`bg-white text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded-lg shadow-sm flex items-center ${
            selectedInvoices.length > 0 ? 'hover:bg-gray-50' : 'opacity-50 cursor-normal'
          }`}
          disabled={selectedInvoices.length === 0}
        >
          <DownloadIcon className="w-5 h-5 mr-2" />
          {allSelected ? 'Descargar todos' : 'Descargar seleccionados'}
        </button>
      </div>
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="w-12 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input
                  type="checkbox"
                  checked={allSelected}
                  onChange={handleSelectAll}
                  className="form-checkbox h-5 w-5 text-primary-600 border-gray-300 rounded focus:ring-primary-500 focus:ring-offset-0"
                />
              </th>
              <th className="w-1/3 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Factura</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Fecha de facturación
                <span className="ml-1 inline-block">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 2.5V9.5M6 9.5L9 6.5M6 9.5L3 6.5" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </span>
              </th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Estado</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Monto</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Plan</th>
              <th className="w-1/6 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {invoices.map((invoice) => (
              <tr key={invoice.id} className="hover:bg-gray-50">
                <td className="w-12 px-6 py-4 whitespace-nowrap">
                  <input
                    type="checkbox"
                    checked={selectedInvoices.includes(invoice.id)}
                    onChange={() => handleSelectInvoice(invoice.id)}
                    className="form-checkbox h-5 w-5 text-primary-600 border-gray-300 rounded focus:ring-primary-500 focus:ring-offset-0"
                  />
                </td>
                <td className="w-1/3 px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <InvoiceIcon className="flex-shrink-0 h-5 w-5 text-primary-600 mr-3" />
                    <span className="text-sm font-medium text-gray-900">{invoice.id}</span>
                  </div>
                </td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.date}</td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap">
                  {invoice.status === 'Pago' ? (
                    <span className="px-2 py-1 inline-flex items-center text-xs leading-5 font-medium rounded-full bg-green-50 text-green-700">
                      <CheckIcon />
                      <span className="ml-1">Pago</span>
                    </span>
                  ) : (
                    <span className="px-2 py-1 inline-flex text-xs leading-5 font-medium rounded-full bg-yellow-100 text-yellow-700">
                      {invoice.status}
                    </span>
                  )}
                </td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">USD {invoice.amount.toFixed(2)}</td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invoice.plan}</td>
                <td className="w-1/6 px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <a href="#" className="text-primary-600 hover:text-primary-900">Descargar</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
