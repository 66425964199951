import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { TreeNode } from 'components/TreeSelector/TreeSelector';

interface TreeState {
    selectedNodes: Set<string>;
    rootNode: TreeNode | null;
    isInitialized: boolean;
}

type TreeAction = 
    | { type: 'SELECT_NODE'; nodeId: string }
    | { type: 'UNSELECT_NODE'; nodeId: string; parentId?: string }
    | { type: 'SELECT_MULTIPLE'; nodeIds: string[] }
    | { type: 'UNSELECT_MULTIPLE'; nodeIds: string[]; parentIds?: string[] }
    | { type: 'UNSELECT_SINGLE_WITH_CHILDREN'; nodeId: string }
    | { type: 'SET_ROOT_NODE'; node: TreeNode }
    | { type: 'UNSELECT_ALL' };

const initialState: TreeState = {
    selectedNodes: new Set(),
    rootNode: null,
    isInitialized: false
};

const treeReducer = (state: TreeState, action: TreeAction): TreeState => {
    switch (action.type) {
        case 'SELECT_NODE': {
            const newSelected = new Set(state.selectedNodes);
            newSelected.add(action.nodeId);
            return { ...state, selectedNodes: newSelected };
        }
        case 'UNSELECT_NODE': {
            const newSelected = new Set(state.selectedNodes);
            newSelected.delete(action.nodeId);
            if (action.parentId) {
                newSelected.delete(action.parentId);
            }
            return { ...state, selectedNodes: newSelected };
        }
        case 'SELECT_MULTIPLE': {
            const newSelected = new Set(state.selectedNodes);
            action.nodeIds.forEach(id => newSelected.add(id));
            return { 
                ...state, 
                selectedNodes: newSelected,
                isInitialized: true 
            };
        }
        case 'UNSELECT_MULTIPLE': {
            const newSelected = new Set(state.selectedNodes);
            action.nodeIds.forEach(id => newSelected.delete(id));
            if (action.parentIds) {
                action.parentIds.forEach(id => newSelected.delete(id));
            }
            return { ...state, selectedNodes: newSelected };
        }
        case 'UNSELECT_SINGLE_WITH_CHILDREN': {
            const newSelected = new Set(state.selectedNodes);
            newSelected.delete(action.nodeId);
            return { ...state, selectedNodes: newSelected };
        }
        case 'SET_ROOT_NODE':
            return {
                ...state,
                rootNode: action.node
            };
        case 'UNSELECT_ALL':
            return {
                ...state,
                selectedNodes: new Set()
            };
        default:
            return state;
    }
};

const TreeContext = createContext<{
    state: TreeState;
    dispatch: React.Dispatch<TreeAction>;
} | undefined>(undefined);

export const TreeSelectionProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(treeReducer, initialState);
    return (
        <TreeContext.Provider value={{ state, dispatch }}>
            {children}
        </TreeContext.Provider>
    );
};

export const useTree = () => {
    const context = useContext(TreeContext);
    if (!context) {
        throw new Error('useTree must be used within a TreeProvider');
    }
    return context;
};
