import { useSubscription } from "graphql-hooks";

export const query = `
subscription ImportTasksProgress {
    importTasksProgress {
        tasks {
            id
            classification_id
            classification_name
            quantity
            created_at
            updated_at
            status
            uploaded_products
            failed_products
        }
    }
}
`;

export interface AmazonImportTask {
    id: string;
    classification_id: string;
    classification_name: string;
    quantity: number;
    created_at: string;
    updated_at: string;
    status: string;
    uploaded_products: number;
    failed_products: number;
}

export const useImportTasksProgressSubscription = (
    onData: (data: AmazonImportTask[]) => void
) => {
    useSubscription({
        query,
        variables: {}
    }, (response: any) => {
        console.log('useImportTasksProgressSubscription - raw response:', response);
        if (response.error) {
            console.error('useImportTasksProgressSubscription - error:', response.error);
        }
        const data = response.data;
        console.log('useImportTasksProgressSubscription - parsed data:', data);
        if(data) onData(data.importTasksProgress.tasks);
    });
}; 