import React, { useEffect, useState } from 'react';
import { useSearchCancelledOrderManual } from 'hooks/useSearchCancelledOrders';
import AntDesignAmazonCircleFilled from '../utils/iconComponents/AmazonIconComponent';
import ArcticonsMercadoLibre from '../utils/iconComponents/MercadoLibreComponent';
import { AddManualProduct } from 'components/addProduct/manual/AddManualProduct';
import { useEditItemByIdManual } from 'hooks/useEditItemById';
import _ from 'lodash';
import { useGetMlItemByIdManual } from 'hooks/useGetMlItemById';
import EditIcon from 'components/utils/iconComponents/EditIcon';
import PlayIcon from 'components/utils/iconComponents/PlayIcon';
import PauseIcon from 'components/utils/iconComponents/PauseIcon';
import { useUpdateArticleStatusManual } from 'hooks/useUpdateArticleStatus';

const OrderDetail = ({ orderInfo }) => {
  const [precio, setPrecio] = useState();
  const [dateCreated, setDateCreated] = useState();
  const [checkStock, setCheckStock] = useState(false);
  const [openModalProductForm, setOpenModalProductForm] = useState(false);
  const [productData, setProductData] = useState({});
  const [statusProduct, setStatusProduct] = useState('');
  const { searchCancelledOrders } = useSearchCancelledOrderManual();

  const { updateArticleStatus, loading: loadingUpdate } =
    useUpdateArticleStatusManual();

  const { getMlItemById } = useGetMlItemByIdManual();
  const { updateItemById, loading: loadingFetch } = useEditItemByIdManual();

  useEffect(() => {
    if (orderInfo) {
      setCheckStock(false);
      setPrecio(orderInfo.currency_id + '' + orderInfo.unit_price);
      const d = new Date(orderInfo.dateCreated);
      const day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const month = d.getMonth() + 1;
      const year = d.getFullYear();
      const hour = d.getHours();
      const minutes = d.getMinutes();
      const seconds = d.getSeconds();
      const date = `${day}/${month}/${year} ${hour}:${minutes}:${seconds}`;
      setDateCreated(date);

      if (orderInfo && Object.keys(orderInfo?.productInfo).length > 0) {
        setStatusProduct(orderInfo.productInfo.status);
      }

      async function getCancelledOrder() {
        const data = await searchCancelledOrders({
          variables: {
            item: orderInfo.id,
          },
        });

        if (data.data.searchCancelledOrders.orders.length > 0) {
          setCheckStock(true);
        }
      }

      async function getDataInfoItem(itemId) {
        const dataItem = await getMlItemById({
          variables: {
            id: itemId,
          },
        });

        if (Object.keys(dataItem?.data?.getMlItemById).length > 0) {
          setProductData(dataItem?.data?.getMlItemById);
        }
      }

      // getDataInfoItem(orderInfo.id);
      getCancelledOrder();
    }
  }, [orderInfo]);

  const extractId = (url) => {
    const match = url.match(/D_(.+?)(?:-.{1}\.jpg)$/);
    return match ? match[1] : null;
  };

  const onSendCorrectionToMlHandler = async (itemId, payload) => {
    console.info('payload to correction...', payload);
    //usamos esta funcion mientras se envia correccion a ML
    const newPayload = {
      available_quantity: payload.stock,
      currency_id: payload.currencyId,
      price: payload.price,
    };

    const { data } = await updateItemById({
      variables: {
        itemId,
        formData: newPayload,
      },
    });

    if (data) {
      console.info('data..', data);
      setOpenModalProductForm(false);
    }
  };

  const editItemPayload = async (itemId, payload) => {
    // console.info('payload...', payload);

    let newPayload;
    if (!payload.hasOwnProperty('fromAttributesTab')) {
      const newPictures = _.uniq(
        payload.pictures.map((picture) => {
          if (picture?.id) {
            return {
              source: picture.link,
            };
          }
          return {
            id: extractId(picture?.source),
          };
        })
      );
      newPayload = Object.assign(payload, {
        //asignar las variables correctas para ML
        available_quantity: payload.stock,
        seller_custom_field: payload.sku,
        currency_id: payload.currencyId,
        pictures: newPictures,
      });
      delete payload.stock;
      delete payload.sku;
      delete payload.currencyId;
      delete payload.categoryId;
      delete newPayload.picturesIds;
    } else {
      delete payload.available_quantity;
      delete payload.currency_id;
      delete payload.pictures;
      delete payload.seller_custom_field;
      delete payload.fromAttributesTab;
      newPayload = payload;
    }

    console.info('newPayload...', newPayload);

    const { data } = await updateItemById({
      variables: {
        itemId,
        formData: newPayload,
      },
    });

    console.info('data...', data);

    if (data) {
      setOpenModalProductForm(false);
    }
  };

  const onUpdateStatusHandler = async (status, item) => {
    const statusResponse = await updateArticleStatus({
      variables: {
        item,
        status,
      },
    });

    if (statusResponse?.data?.updateArticleStatus?.status) {
      if (statusResponse?.data?.updateArticleStatus?.status == 'Activo') {
        setStatusProduct('active');
      } else if (
        statusResponse?.data?.updateArticleStatus?.status == 'Pausado'
      ) {
        setStatusProduct('paused');
      } else {
        setStatusProduct(statusResponse?.data?.updateArticleStatus?.status);
      }
    }
  };

  return (
    <>
      <div className='w-full h-24 bg-white rounded-xl shadow border border-gray-200 flex'>
        <div className='w-full'>
          <div className='w-full inline-flex p-4 ext-sm font-normal font-["Inter"] leading-tight items-center'>
            <div className='mr-4 text-gray-500 flex items-center'>
              ML:{' '}
              <span className='text-primary-700'>
                <a href={orderInfo?.item?.permalink} target='_blank'>
                  {orderInfo?.id}
                </a>
              </span>
              <div
                className={`ml-2 flex justify-end items-center ${
                  orderInfo ? 'visible' : 'hidden'
                }`}
              >
                {statusProduct == 'paused' && (
                  <div
                    onClick={() =>
                      onUpdateStatusHandler(
                        'active',
                        orderInfo?.productInfo?.id
                      )
                    }
                    aria-hidden={true}
                  >
                    <PlayIcon title='Activar artículo' />
                  </div>
                )}
                {statusProduct == 'active' && (
                  <div
                    onClick={() =>
                      onUpdateStatusHandler(
                        'paused',
                        orderInfo?.productInfo?.id
                      )
                    }
                    aria-hidden={true}
                  >
                    <PauseIcon title='Pausar artículo' />
                  </div>
                )}
                <div onClick={() => setOpenModalProductForm(true)}>
                  <EditIcon title='Editar artículo' />
                </div>
              </div>
            </div>

            <div className='mr-4 text-gray-500'>
              Proveedor:{' '}
              <span
                className={`${
                  checkStock ? 'text-orange-400' : 'text-primary-700'
                } inline-flex`}
                title={`${checkStock ? 'Verificar producto en stock' : ''}`}
              >
                <a
                  href={orderInfo?.item?.amazonUrl}
                  target='_blank'
                  className='mr-1'
                >
                  {checkStock && '!'} {orderInfo?.item?.seller_custom_field}
                </a>
                {checkStock && (
                  <>
                    <a
                      href={`https://www.amazon.com/gp/your-account/order-history/?search=${orderInfo?.item?.seller_custom_field}`}
                      target='_blank'
                    >
                      <AntDesignAmazonCircleFilled />
                    </a>
                    <a
                      href={`https://www.mercadolibre.com.uy/ventas/omni/listado?search=${orderInfo?.item?.seller_custom_field}`}
                      target='_blank'
                    >
                      <ArcticonsMercadoLibre />
                    </a>
                  </>
                )}
              </span>
            </div>
            {orderInfo?.productInfo?.catalog_product_id && (
              <div className='mr-4 text-gray-500'>
                Catalogo:{' '}
                <span className='text-primary-700'>
                  <a
                    href={`https://mercadolibre.com.uy/p/${orderInfo?.productInfo?.catalog_product_id}`}
                    target='_blank'
                  >
                    {orderInfo?.productInfo?.catalog_product_id}
                  </a>
                </span>
              </div>
            )}
          </div>
          <div className='w-full inline-flex pt-0 pl-4 pr-4 pb-4 ext-sm font-normal font-["Inter"] leading-tight'>
            <div className='mr-4 text-gray-500'>
              Precio: <span className='text-primary-700'>{precio}</span>
            </div>
            <div className='mr-4 text-gray-500'>
              Fecha: <span className='text-primary-700'>{dateCreated}</span>
            </div>
            <div className='mr-4 text-gray-500'>
              Pack:{' '}
              <span className='text-primary-700'>{orderInfo?.order_id}</span>
            </div>
          </div>
        </div>
      </div>
      {orderInfo && Object.keys(orderInfo?.productInfo).length > 0 && (
        <AddManualProduct
          visible={openModalProductForm}
          identifier={orderInfo.productInfo?.seller_custom_field}
          closeButtonLabel={() => null}
          onCancel={() => setOpenModalProductForm(false)}
          onPublish={() => null}
          titleAsinProduct={orderInfo.productInfo?.title || ''}
          price={orderInfo.productInfo?.price || 0}
          currency={orderInfo.productInfo?.currency_id || ''}
          productDescriptionAsin={orderInfo.productInfo?.description || ''}
          productImages={orderInfo.productInfo?.pictures || []}
          productName={orderInfo.productInfo?.title || ''}
          categoryPath={orderInfo.productInfo?.categoryPath || []} //--> falta
          attributeValues={orderInfo.productInfo?.attributes || []}
          formTitle='Editar producto'
          buttonName={'Editar'}
          onSubmit={(payload) =>
            !orderInfo.productInfo?.catalog_listing
              ? editItemPayload(orderInfo.productInfo?.id, payload)
              : onSendCorrectionToMlHandler(orderInfo.productInfo?.id, payload)
          }
          loadingFetch={loadingFetch}
          soldQuantity={orderInfo.productInfo?.sold_quantity}
          itemId={orderInfo.productInfo?.id}
          stock={orderInfo.productInfo?.available_quantity}
          catalog_listing={orderInfo.productInfo?.catalog_listing}
          permalink={orderInfo.productInfo?.permalink || ''}
          catalog_product_id={orderInfo.productInfo?.catalog_product_id || ''}
        />
      )}
    </>
  );
};

export default OrderDetail;
