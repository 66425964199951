import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useUploadImageToMLManual } from 'hooks/useUploadImageToML';

interface ProductImage {
  _id: string;
  name?: string;
  size?: number;
  src?: string;
  width?: number;
  height?: number;
  link?: string;
  variant?: string;
}

interface UploadedImageVariation {
  url: string;
  size: string;
  secure_url: string;
}

interface UploadedImage {
  id: string;
  variations: Array<UploadedImageVariation>;
}

interface ImageSelectorProps {
  onImageListChanged: (images: ProductImage[]) => void;
  productImages: ProductImage[];
  onProductImagesChanged: (images: ProductImage[]) => void;
  onReady? : () => void;
  catalog_listing?: boolean;
}

const UploadIcon = ({ className }: { className: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`${className}`}
  >
    <path d="M12 13v8" />
    <path d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242" />
    <path d="m8 17 4-4 4 4" />
  </svg>
);

export const ImageSelector: React.FC<ImageSelectorProps> = ({
  onImageListChanged,
  productImages,
  onProductImagesChanged,
  onReady = () => null,
  catalog_listing = false
}) => {
  const [allImages, setAllImages] = useState<ProductImage[]>([]);
  const [draggingOver, setDraggingOver] = useState<boolean>(false);

  const { uploadImageToML } = useUploadImageToMLManual();

  // Function to check if an image is from Amazon
  const isAmazonImage = (image: ProductImage) => {
    return image.link?.includes('amazon.com');
  };

  // Function to get base64 from image URL
  const getBase64FromUrl = async (imageUrl: string): Promise<string> => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = () => reject('Failed to read blob as DataURL');
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(blob);
    });
  };

  // Function to process Amazon images by uploading them to ML
  const processAmazonImages = async (amazonImages: ProductImage[]) => {
    const uploadedImages: ProductImage[] = [];

    // Take only the largest image for every variant, and only variants with at least 500x500
    const largeImages = amazonImages.reduce((acc: ProductImage[], image: ProductImage) => {
      const { width, height } = image;
      if (width && height && width >= 500 && height >= 500) {
        const existing = acc.find((i: ProductImage) => i.variant === image.variant);
        const { width: existingWidth, height: existingHeight } = existing || {};
        if (!existing || (existingWidth && existingHeight && width > existingWidth && height > existingHeight)) {
          return [...acc.filter(i => i.variant !== image.variant), image];
        }
      }
      return acc;
    }, []);

    for (const image of largeImages) {
      try {
        const base64 = await getBase64FromUrl(image.link!);
        const { data } = await uploadImageToML({
          variables: {
            base64,
          },
        });
        if (data) {
          const uploadedImage: ProductImage = {
            _id: data.uploadImageToML.id,
            link: data.uploadImageToML.variations[0]?.secure_url,
            variant: data.uploadImageToML.variations[0]?.size,
            width: parseInt(data.uploadImageToML.variations[0]?.size.split('x')[0]),
            height: parseInt(data.uploadImageToML.variations[0]?.size.split('x')[1]),
          };
          uploadedImages.push(uploadedImage);
        }
      } catch (error) {
        console.error('Error processing Amazon image:', error);
      }
    }

    return uploadedImages;
  };

  useEffect(() => {
    const processInitialImages = async () => {
      if (productImages && productImages.length > 0) {
        const mlImages = productImages.filter((img) => !isAmazonImage(img));
        const amazonImages = productImages.filter(isAmazonImage);

        // Process Amazon images by uploading them to ML
        const uploadedAmazonImages = await processAmazonImages(amazonImages);

        // Combine ML images and uploaded Amazon images
        const combinedImages = [...mlImages, ...uploadedAmazonImages];

        setAllImages(combinedImages);
        onImageListChanged(combinedImages);
        onProductImagesChanged(combinedImages);
        onReady();
      }
    };

    processInitialImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productImages]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      for (const file of acceptedFiles) {
        const reader = new FileReader();

        // Wrap the onload event in a Promise to use await
        const fileLoaded = new Promise<string>((resolve, reject) => {
          reader.onload = () => {
            resolve(reader.result as string);
          };
          reader.onerror = reject;
        });

        reader.readAsDataURL(file);
        try {
          const binaryStr = await fileLoaded;

          const { data } = await uploadImageToML({
            variables: {
              base64: binaryStr,
            },
          });

          if (data) {
            const uploadedImage: ProductImage = {
              _id: data.uploadImageToML.id,
              link: data.uploadImageToML.variations[0]?.secure_url,
              variant: data.uploadImageToML.variations[0]?.size,
              width: parseInt(data.uploadImageToML.variations[0]?.size.split('x')[0]),
              height: parseInt(data.uploadImageToML.variations[0]?.size.split('x')[1]),
            };

            setAllImages((prevImages) => [...prevImages, uploadedImage]);
            onProductImagesChanged([...allImages, uploadedImage]);
          }
        } catch (error) {
          console.error('Error uploading dropped image:', error);
        }
      }
    },
    [allImages, uploadImageToML, onProductImagesChanged]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
    },
    multiple: true,
  });

  const handleDelete = (imageId: string) => {
    const remainingImages = allImages.filter((img) => img._id !== imageId);
    setAllImages(remainingImages);
    onProductImagesChanged(remainingImages);
  };

  const thumbnails = allImages.filter(
    (img) =>
      (img.width === 75 && img.height === 75) ||
      img._id ||
      img.link?.includes('-S.jpg')
  );

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDraggingOver(false);
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, imageId: string) => {
    e.dataTransfer.setData('text/plain', imageId);
  };

  return (
    <div className='w-52'>
      <div className='mt-2'>
        {thumbnails.length > 0 && (
          <div className='relative mb-2 w-fit'>
            <img 
              alt='' 
              src={thumbnails[0].link || thumbnails[0].src} 
              className='w-[160px] h-[160px] object-cover'
            />
            {!catalog_listing && (
            <div
              className={`absolute top-0 right-0 text-sm p-1 bg-white opacity-50 font-bold cursor-pointer`}
              onClick={() => handleDelete(thumbnails[0]._id)}
            >
              X
            </div>
            )}
          </div>
        )}
        <div 
          className='grid grid-cols-2 gap-x-[10px] gap-y-2'
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {thumbnails.slice(1).map((image) => (
            <div 
              key={image._id} 
              className='relative w-fit'
              draggable
              onDragStart={(e) => handleDragStart(e, image._id)}
            >
              <img 
                alt='' 
                src={image.link || image.src} 
                className='w-[75px] h-[75px] object-cover'
              />
              {!catalog_listing && (
              <div
                className={`absolute top-0 right-0 text-sm p-1 bg-white opacity-50 font-bold cursor-pointer`}
                onClick={() => handleDelete(image._id)}
              >
                X
              </div>
              )}
            </div>
          ))}
          {!catalog_listing && (
            <div
            {...getRootProps()}
            className={`bg-gray-300 rounded-md text-white flex items-center justify-center cursor-pointer
              ${thumbnails.length === 0 ? 'w-[140px] h-[140px]' : 'w-[75px] h-[75px]'}`}
          >
            <input {...getInputProps()} />
            <UploadIcon className={`text-gray-400 ${thumbnails.length === 0 ? 'w-12 h-12' : 'w-6 h-6'}`} />
          </div>
          )}
        </div>
      </div>
    </div>
  );
};
