import React, { useEffect, useState } from 'react';
import { useGetAmazonTreeNodeManual } from 'hooks/useGetAmazonTreeNode';
import { useTree } from 'contexts/TreeSelectionContext';
import _ from 'lodash';
import { ImportableItemsPerClassification } from 'hooks/useGetImportableItemsByAmazonClassifications';
import { Scrollbar } from 'react-scrollbars-custom';

export interface TreeNode {
    id: string;
    name: string;
    childrenIds: string[];
    children: TreeNode[];
}

interface TreeSelectorProps {
    nodeId: string;
    expandedNodes?: string[];
    onNodeToggle?: (nodeId: string, isExpanded: boolean) => void;
    itemsPerClassification?: ImportableItemsPerClassification[];
    onUnselectedItem?: () => void;
    preSelected?: boolean;
    parentNode?: TreeNode;
}

export const TreeSelector: React.FC<TreeSelectorProps> = ({
    nodeId,
    expandedNodes = [],
    onNodeToggle,
    onUnselectedItem,
    preSelected,
    parentNode
}) => {
    const { getAmazonTreeNode, data, loading } = useGetAmazonTreeNodeManual();
    const { state, dispatch } = useTree();
    const [showChildren, setShowChildren] = useState(nodeId === 'root');
    const [node, setNode] = useState<TreeNode | null>(null);
    const [isChecked, setIsChecked] = useState(state.selectedNodes.has(nodeId));

    useEffect(() => {
        getAmazonTreeNode({
            variables: {
                id: nodeId,
                levels: 9
            }
        });
    }, [nodeId]);

    useEffect(() => {
        setNode(data?.getAmazonTreeNode);

        if (data?.getAmazonTreeNode && nodeId === 'null') {
            dispatch({ type: 'SET_ROOT_NODE', node: data.getAmazonTreeNode });
        }
    }, [data]);

    useEffect(() => {
        setIsChecked(node ? state.selectedNodes.has(node.id) : false);
    }, [node, state.selectedNodes]);

    const handleCheckboxChange = (checked: boolean, fromChild: boolean = false) => {
        if (!node) return;

        if (checked) {
            const childrenIds = getAllChildrenIds(node);
            dispatch({ type: 'SELECT_MULTIPLE', nodeIds: [node.id, ...childrenIds] });
        } else {
            if (fromChild) {
                dispatch({ type: 'UNSELECT_SINGLE_WITH_CHILDREN', nodeId: node.id });
            } else {
                const childrenIds = getAllChildrenIds(node);
                dispatch({ type: 'UNSELECT_MULTIPLE', nodeIds: [node.id, ...childrenIds] });
            }
            onUnselectedItem?.();
        }
    };

    const getAllChildrenIds = (node: TreeNode): string[] => _.flatMap(node.children, child => [child.id, ...getAllChildrenIds(child)]);

    const handleToggle = (nodeId: string) => {
        if (onNodeToggle) {
            onNodeToggle(nodeId, !expandedNodes.includes(nodeId));
        }
    };

    if (loading) return <div className="pl-8 select-none">Cargando...</div>;
    if (!node) return null;
    
    const sortedChildren = _.orderBy(
        node.children,
        [
            child => child?.childrenIds?.length === 0 ? 1 : 0,
            child => child?.name?.toLowerCase()
        ],
        ['asc', 'asc']
    );

    if (node.id === 'null') {
        return (
            <div className="flex flex-col select-none" style={{ height: 'calc(100vh - 427px)' }} data-testid="tree-root">
                <div className="flex-1 overflow-hidden" data-testid="tree-container">
                    <Scrollbar
                        style={{ width: '100%', height: '100%' }}
                        trackYProps={{
                            style: {
                                width: '8px',
                                background: '#f1f1f1',
                                borderRadius: '4px',
                                marginTop: '-10px',
                                height: '100%',
                                padding: 0
                            }
                        }}
                        thumbYProps={{
                            style: {
                                background: '#c1c1c1',
                                borderRadius: '4px'
                            }
                        }}
                    >
                        <div data-testid="tree-content">
                            {sortedChildren.map(childNode => (
                                <TreeSelector
                                    key={childNode.id}
                                    nodeId={childNode.id}
                                    onUnselectedItem={() => handleCheckboxChange(false, true)}
                                    preSelected={isChecked}
                                    parentNode={node}
                                    expandedNodes={expandedNodes}
                                    onNodeToggle={handleToggle}
                                />
                            ))}
                        </div>
                    </Scrollbar>
                </div>
            </div>
        );
    }

    return (
        <div className="select-none cursor-pointer" key={node.id} data-testid={`tree-node-${node.id}`} data-node-id={node.id}>
            <div className="flex items-center">
                <span
                    onClick={() => sortedChildren.length && handleToggle(node.id)}
                    className="inline-block w-4 text-center align-middle text-gray-700"
                    data-testid={`tree-toggle-${node.id}`}
            >
                {expandedNodes.includes(node.id) ? '-' : sortedChildren.length ? '+' : ' '}
                </span>
                { node.children.length === 0 ? (
                    <input
                        type="checkbox"
                        className="form-checkbox ml-1 text-primary-700"
                        checked={isChecked}
                        onChange={(e) => handleCheckboxChange(e.target.checked)}
                        data-testid={`tree-checkbox-${node.id}`}
                    />
                ) : (
                    <span className="inline-block w-6 text-center text-yellow-500">
                        <svg data-slot="icon" fill="currentColor" width="28" height="28" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path d="M19.906 9c.382 0 .749.057 1.094.162V9a3 3 0 0 0-3-3h-3.879a.75.75 0 0 1-.53-.22L11.47 3.66A2.25 2.25 0 0 0 9.879 3H6a3 3 0 0 0-3 3v3.162A3.756 3.756 0 0 1 4.094 9h15.812ZM4.094 10.5a2.25 2.25 0 0 0-2.227 2.568l.857 6A2.25 2.25 0 0 0 4.951 21H19.05a2.25 2.25 0 0 0 2.227-1.932l.857-6a2.25 2.25 0 0 0-2.227-2.568H4.094Z"></path>
                        </svg>
                    </span>
                )}
                    <span className="ml-2 text-gray-900" onClick={() => sortedChildren.length && handleToggle(node.id)} data-testid={`tree-label-${node.id}`}>
                        {node.name}
                    </span>
                </div>
            {expandedNodes.includes(node.id) &&
                sortedChildren.length > 0 &&
                sortedChildren.map(childNode => (
                    <div className="pl-4" key={childNode.id}>   
                        <TreeSelector
                            nodeId={childNode.id}
                            onUnselectedItem={() => handleCheckboxChange(false, true)}
                            preSelected={isChecked}
                            parentNode={node}
                            expandedNodes={expandedNodes}
                            onNodeToggle={handleToggle}
                        />
                    </div>
                ))}
        </div>
    );
};
