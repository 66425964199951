import React, { useEffect, useState } from 'react';
import { AoTextInputProps } from '../types/MLForm.types';
import { Control, Controller } from 'react-hook-form';

const a = {
  title: 'Tienda de campaña Beesky impermeable 6 personas',
  category_id: 'MLU1363',
  price: 19900,
  currency_id: 'UYU',
  available_quantity: 1,
  buying_mode: 'buy_it_now',
  condition: 'new',
  listing_type_id: 'gold_special',
  description: {
    plain_text:
      'Tienda impermeable: Hecha con poliéster 185T, descansa tranquilo y mantente protegido bajo la cubierta de lluvia tratada con PU de 1000MM.\\nEstable y seguro: Los fuertes postes de fibra de vidrio garantizan la estabilidad y una larga vida útil de uso, la tienda de campaña con estacas la mantiene estable en días ventosos.\\nFácil de montar: Montar esta tienda de campaña manualmente es fácil y divertido. Con solo 2 postes de fibra de vidrio, montar y desmontar esta tienda es rápido y sencillo. Se recomienda que dos personas la armen juntas, pero una persona también puede hacerlo sin dificultad en menos de 10 minutos.\\nPuerto de acceso eléctrico: El puerto de acceso eléctrico te permite conectar un cable desde tu dispositivo dentro de la tienda de campaña a una fuente de energía externa.\\nEquipada con una base: Puedes colocarla debajo de tu tienda como una alfombra de entrada para colocar los zapatos, también puedes usarla como una manta de picnic.',
  },
  attributes: [
    { id: 'BRAND', value_name: 'Beesky' },
    { id: 'LINE', value_name: 'Beesky' },
    { id: 'MODEL', value_name: 'Beesky' },
    {
      id: 'ALPHANUMERIC_MODEL',
      value_name: '768253591460, upc; 0768253591460, ean',
    },
    { id: 'COLOR', value_name: 'Amarillo Claro+Celeste' },
    { id: 'TENT_TYPE', value_name: 'Carpas' },
    { id: 'FLOORING_MATERIAL', value_name: 'Malla' },
    { id: 'WALL_MATERIAL', value_name: 'Malla' },
    { id: 'INTEGER_CAPACITY', value_name: '6' },
    {
      id: 'TENT_COMPARTMENTS',
      value_name: 'Estacas; Lona impermeable; Bolsa de transporte',
    },
    { id: 'WEIGHT', value_name: '6.99 kg' },
    { id: 'HEIGHT', value_name: '2.59 m' },
    { id: 'WIDTH', value_name: '3.05 m' },
    { id: 'DEPTH', value_name: '1.83 m' },
    { id: 'IMPERMEABILITY', value_name: 'Impermeable mm' },
    { id: 'INCLUDES_MOSQUITO_NET', value_name: 'No' },
    { id: 'GTIN', value_name: '768253591460' },
  ],
  pictures: [
    {
      source:
        'http://http2.mlstatic.com/D_NQ_NP_988226-MLU73904553309_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_Q_NP_988226-MLU73904553309_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_NQ_NP_651504-MLU73904553319_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_Q_NP_651504-MLU73904553319_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_NQ_NP_866193-MLU73805465222_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_Q_NP_866193-MLU73805465222_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_NQ_NP_940490-MLU73904739853_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_Q_NP_940490-MLU73904739853_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_NQ_NP_960073-MLU73805465240_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_Q_NP_960073-MLU73805465240_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_NQ_NP_718513-MLU73805151732_012024-F.jpg',
    },
    {
      source:
        'http://http2.mlstatic.com/D_Q_NP_718513-MLU73805151732_012024-F.jpg',
    },
  ],
  shipping: {
    mode: 'me2',
    local_pick_up: false,
    free_shipping: true,
    store_pick_up: false,
  },
  sale_terms: [{ id: 'MANUFACTURING_TIME', value_name: '15 días' }],
  seller_custom_field: 'B09Z6CZSSM',
};

function AoTextInput({
  opts,
  label,
  config,
  attributes,
  value,
  valueType,
  catalog_listing = false
}: AoTextInputProps) {
  const [inputValue, setInputValue] = useState<any>('');
  // const [inputValue, setInputValue] = useState(valueType === 'number' && attributes?.default_unit_id ? opts.value : value);
  const id = attributes.id;
  const isRequired = attributes?.tags?.includes('required');
  const { control, ...optRest }: { control: Control<any> } = opts;

  let fieldRef: any = null;

  attributes.value_type = valueType ?? attributes.value_type;

  useEffect(() => {
    
      if (valueType === 'number' && typeof parseFloat(value) !== 'number')
        return;
      if (valueType === 'number' && attributes?.default_unit_id) {
        fieldRef.onChange(value ? value : null);
        setInputValue(opts.value);
      } else if (valueType === 'number') {
        fieldRef.onChange(parseFloat(value));
        setInputValue(parseFloat(value));
      } else {
        fieldRef.onChange(value);
        setInputValue(value);
      }

  }, [value]);

  return (
    <div className='my-2 grow-0 md:basis-1/3 lg:basis-1/4 px-1'>
      <label
        className='block text-gray-700 text-sm font-medium mb-2 size-3.5 truncate'
        htmlFor={id}
        title={label}
      >
        {label}{' '}
        {attributes?.default_unit_id ? `(${attributes?.default_unit_id})` : ''}
        {isRequired && <span className='text-red-700'>*</span>}
      </label>

      <Controller
        control={control}
        name={id}
        rules={{ required: isRequired }}
        render={({ field, fieldState: { error } }) => {
          fieldRef = field;

          return (
            <>
              <input
                //   className='shadow appearance-none border
                // rounded w-full py-2 px-3 text-gray-700
                // leading-tight focus:outline-none focus:shadow-outline'
                className='shadow border-gray-300 border rounded-lg w-full py-2 px-3 text-gray-900 leading-tight focus:outline-none focus:shadow-outline'
                id={id}
                disabled = {!catalog_listing ? false : true}
                placeholder={config?.example ? `Por ej. ${config.example}` : ''}
                {...field}
                {...optRest}
                type={valueType ?? 'text'}
                value={inputValue}
                onChange={(event) => {
                  if (
                    valueType === 'number' &&
                    typeof parseFloat(event.target.value) !== 'number'
                  )
                    return;
                  setInputValue(event.target.value);
                  if (attributes?.default_unit_id) {
                    field.onChange(
                      event.target.value
                        ? `${event.target.value} ${attributes?.default_unit_id}`
                        : null
                    );
                    // field.onChange(event.target.value ? `${event.target.value}` : null);
                  } else {
                    field.onChange(event.target.value);
                  }
                }}
              />
              {error && (
                <p className='text-red-500 text-xs italic'>
                  Por favor ingrese {label}
                </p>
              )}
            </>
          );
        }}
      />

      <div className='text-gray-500 text-xs truncate' title={config.hint}>
        {config.hint}
      </div>
    </div>
  );
}

function AoNumberInput(props: AoTextInputProps) {
  return AoTextInput({
    ...props,
    valueType: 'number',
    opts: { ...props.opts, value: parseFloat(props.value) },
  });
}

export { AoTextInput, AoNumberInput };
