import React, { useState, useEffect } from 'react';
import { useGetImportableItemsByAmazonClassificationsManual } from 'hooks/useGetImportableItemsByAmazonClassifications';
import { Scrollbar } from 'react-scrollbars-custom';

interface GroupedItems {
    path: string;
    items: Array<{
        name: string;
        items: number;
        selected?: boolean;
        selectedCount?: number;
    }>;
}

const groupItemsByPath = (items: Array<{classificationPath: string; classificationName: string; items: number}>) => {
    const grouped = new Map<string, GroupedItems>();
    
    items.forEach(item => {
        if (!item.classificationPath || item.classificationPath === item.classificationName) {
            const path = '';
            if (!grouped.has(path)) {
                grouped.set(path, { path, items: [] });
            }
            grouped.get(path)!.items.push({ 
                name: item.classificationName, 
                items: item.items 
            });
            return;
        }

        const parts = item.classificationPath.split(' / ');
        const name = item.classificationName;
        const isLastSegmentEqualToName = parts[parts.length - 1] === name;
        const path = isLastSegmentEqualToName ? parts.slice(0, -1).join(' / ') : item.classificationPath;
        
        if (!grouped.has(path)) {
            grouped.set(path, { path, items: [] });
        }
        
        grouped.get(path)!.items.push({ 
            name, 
            items: item.items 
        });
    });
    
    return Array.from(grouped.values()).sort((a, b) => a.path.localeCompare(b.path));
};

interface ImportSelectionEditorProps {
    selectionId: string;
    onSelectionChange?: (selectedItems: Array<{classificationId: string; quantity: number}>) => void;
}

export const ImportSelectionEditor: React.FC<ImportSelectionEditorProps> = ({ selectionId, onSelectionChange }) => {
    const { getImportableItems, loading, error, data } = useGetImportableItemsByAmazonClassificationsManual();
    const [groupedItems, setGroupedItems] = useState<GroupedItems[]>([]);
    const [retryCount, setRetryCount] = useState(0);
    const [availableItems, setAvailableItems] = useState(10000);
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 2000; // 2 segundos
    const [editingItem, setEditingItem] = useState<{path: string, name: string} | null>(null);

    useEffect(() => {
        getImportableItems({ variables: { selectionId } })
            .catch(err => {
                if (retryCount < MAX_RETRIES) {
                    setTimeout(() => {
                        setRetryCount(prev => prev + 1);
                    }, RETRY_DELAY);
                }
                console.error('Error fetching import categories:', err);
            });
    }, [getImportableItems, selectionId, retryCount]);

    useEffect(() => {
        if (data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification) {
            setGroupedItems(groupItemsByPath(
                data.getImportableItemsByAmazonClassificationsSelection.itemsPerClassification
            ).map(group => ({
                ...group,
                items: group.items.map(item => ({
                    ...item,
                    selected: false,
                    selectedCount: undefined
                }))
            })));
            setRetryCount(0); // Reset retry count on successful load
        }
    }, [data]);

    useEffect(() => {
        onSelectionChange?.(groupedItems.flatMap(group => group.items.map(item => {
            const classificationId = data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification.find((i: any) => i.classificationName === item.name)?.classificationId;
            return {
                classificationId,
                selected: item.selected,
                quantity: item.selectedCount || 0
            }
        })).filter(item => item.selected).map(({classificationId, quantity}) => ({
            classificationId,
            quantity
        })));
    }, [groupedItems]);

    const handleSelection = (groupPath: string, itemName: string, itemId: string, itemCount: number) => {
        setGroupedItems(prevGroups => 
            prevGroups.map(group => {
                if (group.path === groupPath) {
                    const classificationId = data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification.find((i: any) => i.classification_name === itemName)?.classificationId;
                    return {
                        ...group,
                        items: group.items.map((item: any) => 
                            item.name === itemName 
                                ? { ...item, selected: true, selectedCount: itemCount, classificationId }
                                : item
                        )
                    };
                }
                return group;
            })
        );
        setAvailableItems(prev => prev - itemCount);
    };

    const handleDeselection = (groupPath: string, itemName: string) => {
        setGroupedItems(prevGroups => 
            prevGroups.map(group => {
                if (group.path === groupPath) {
                    const item = group.items.find(i => i.name === itemName);
                    const classificationId = data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification.find((i: any) => i.classificationName === itemName)?.classificationId;
                    if (item?.selectedCount) {
                        const selectedCount = item.selectedCount ?? 0;
                        setAvailableItems(prev => prev + selectedCount);
                    }
                    return {
                        ...group,
                        items: group.items.map((item: any) => 
                            item.name === itemName 
                                ? { ...item, selected: false, selectedCount: undefined, classificationId }
                                : item
                        )
                    };
                }
                return group;
            })
        );
    };

    const handleUpdateCount = (groupPath: string, itemName: string, newCount: number) => {
        setGroupedItems(prevGroups => 
            prevGroups.map(group => {
                if (group.path === groupPath) {
                    const item = group.items.find(i => i.name === itemName);
                    if (item) {
                        const oldCount = item.selectedCount || 0;
                        const difference = newCount - oldCount;
                        setAvailableItems(prev => prev - difference);

                        return {
                            ...group,
                            items: group.items.map((item: any) => 
                                item.name === itemName 
                                    ? { ...item, selectedCount: newCount }
                                    : item
                            )
                        };
                    }
                }
                return group;
            })
        );
    };

    if (loading) return (
        <div className="m-8 text-gray-500">Cargando...</div>
    );

    if (error && retryCount >= MAX_RETRIES) {
        return <div className="bg-white rounded-xl border border-gray-200 shadow-sm p-6">
            <p className="text-gray-900 mb-4">Error al cargar las categorías. Por favor, intente nuevamente más tarde.</p>
            <button 
                onClick={() => setRetryCount(0)}
                className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
            >
                Reintentar
            </button>
            <br />
            <small className="text-gray-500 mt-2 block">{error?.toString()}</small>
        </div>
    }

    if (!data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification?.length) {
        return (
            <div className="bg-white rounded-xl border border-gray-200 shadow-sm p-6">
                <div className="text-center">
                    <div className="text-gray-500 mb-2">No hay nuevos productos en las categorías de Amazon seleccionadas</div>
                    <div className="text-sm text-gray-400">Intenta seleccionar otras categorías</div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col h-full" style={{ minWidth: '400px' }}>
            <div className="flex-none mb-4">
                <div className="text-gray-500 mt-2">
                    Items disponibles para importar: 
                    <span className="ml-2 text-primary-700 font-medium">{availableItems.toLocaleString()}</span>
                </div>
            </div>

            <div className="flex-1 flex flex-col bg-white rounded-xl border border-gray-200 shadow-sm overflow-hidden">
                <div className="flex-none">
                    <div className="grid grid-cols-12 gap-4 px-6 py-3 text-sm font-medium text-gray-500 border-b border-gray-200">
                        <div className="col-span-7">Categoría</div>
                        <div className="col-span-2 text-right">Disponibles</div>
                        <div className="col-span-3 text-right">Seleccionados</div>
                    </div>
                </div>

                <div className="flex-1">
                    <Scrollbar
                        style={{ width: '100%', height: 'calc(100vh - 500px)' }}
                        trackYProps={{
                            style: {
                                width: '8px',
                                background: '#f1f1f1',
                                borderRadius: '4px',
                                marginTop: '-10px',
                                height: '100%',
                                padding: 0
                            }
                        }}
                        thumbYProps={{
                            style: {
                                background: '#c1c1c1',
                                borderRadius: '4px'
                            }
                        }}
                    >
                        <div className="pr-2">
                            {groupedItems.map(group => (
                                <React.Fragment key={group.path}>
                                    {group.path && (
                                        <div className="px-6 py-3 font-medium text-gray-900 bg-gray-50 border-y border-gray-200">
                                            {group.path}
                                        </div>
                                    )}
                                    
                                    {group.items.map(item => {
                                        const availableCount = item.items;
                                        const selectedCount = item.selectedCount || 0;
                                        const isEditing = editingItem?.path === group.path && editingItem?.name === item.name;
                                        const classificationId = data?.getImportableItemsByAmazonClassificationsSelection?.itemsPerClassification.find((i: any) => i.classificationName === item.name)?.classificationId;

                                        if (availableCount === 0 && selectedCount === 0) {
                                            return null;
                                        }

                                        return (
                                            <div key={item.name} 
                                                className="grid grid-cols-12 gap-4 px-6 py-3 border-b border-gray-200 items-center hover:bg-gray-50 transition-colors"
                                            >
                                                <div className="col-span-7 text-gray-900">{item.name}</div>
                                                <div className="col-span-2 text-right flex items-center justify-end gap-2 text-gray-600">
                                                    {availableCount - selectedCount > 0 ? <>
                                                        {availableCount - selectedCount}
                                                        <button
                                                            onClick={() => handleSelection(group.path, item.name, classificationId, availableCount)}
                                                            className="p-1 hover:bg-primary-50 rounded-full transition-colors"
                                                            title="Seleccionar categoría"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="h-5 w-5 text-primary-700"
                                                                viewBox="0 0 20 20"
                                                                fill="currentColor"
                                                            >
                                                                <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                                            </svg>
                                                        </button>
                                                    </> : ''}
                                                </div>
                                                <div className="col-span-3 text-right text-gray-600 flex items-center justify-end gap-2">
                                                    {item.selected && (
                                                        <>
                                                            {isEditing ? (
                                                                <input
                                                                    type="number"
                                                                    className="w-20 px-2 py-1 border rounded"
                                                                    value={item.selectedCount || 0}
                                                                    min={0}
                                                                    onChange={(e) => {
                                                                        const value = Math.min(
                                                                            Math.max(0, parseInt(e.target.value) || 0),
                                                                            item.items
                                                                        );
                                                                        handleUpdateCount(group.path, item.name, value);
                                                                    }}
                                                                    onBlur={() => setEditingItem(null)}
                                                                    autoFocus
                                                                />
                                                            ) : (
                                                                <>
                                                                    <button
                                                                        onClick={() => handleDeselection(group.path, item.name)}
                                                                        className="p-1.5 hover:bg-primary-50 rounded-full transition-colors"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-4 w-4 text-primary-700"
                                                                            viewBox="0 0 20 20"
                                                                            fill="currentColor"
                                                                        >
                                                                            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                                                        </svg>
                                                                    </button>
                                                                    {item.selectedCount}
                                                                    <button
                                                                        onClick={() => setEditingItem({ path: group.path, name: item.name })}
                                                                        className="p-1.5 hover:bg-primary-50 rounded-full transition-colors"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            className="h-4 w-4 text-primary-700"
                                                                            viewBox="0 0 20 20"
                                                                            fill="currentColor"
                                                                        >
                                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                        </svg>
                                                                    </button>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </div>
                    </Scrollbar>
                </div>
            </div>
        </div>
    );
};

