import React, { useEffect, useState } from 'react';
import { SearchBox } from 'components/SearchBox';
import { useSearchOrdersManual } from 'hooks/useSearchOrders';
import { useSearchUnreadMessageManual } from 'hooks/useSearchUnreadMessages';
import _ from 'lodash';
import UnreadMessageFilterIconComponent from '../utils/iconComponents/UnreadMessageFilterIconComponent';
import LoadingIconComponent from './iconComponent/LoadingIconComponent';
import { Scrollbar } from 'react-scrollbars-custom';

const OrderList = ({ onOrderSelected }) => {
  const { searchOrders, orders, loading, total, offset } =
    useSearchOrdersManual();
  const { searchUnreadMessages, loading: loadingUnreadMessageOrders } =
    useSearchUnreadMessageManual();
  const [query, setQuery] = useState('');
  const [loadedOrders, setLoadedOrders] = useState([]);
  const [selectedElement, setSelectedElement] = useState();
  const [unreadMessageFilterChecked, setUnreadMessageFilterChecked] =
    useState(false);
  const pageSize = 20;
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setLoadedOrders([]);
    setPage(0);
  }, [query]);

  useEffect(() => {
    async function orderQuery() {
      await searchOrders({
        variables: {
          query,
          offset: page * pageSize,
          limit: pageSize,
        },
      });
    }
    orderQuery();
  }, [page, query]);

  useEffect(() => {
    if (orders?.length) {
      setHasMore(orders.length === pageSize);
      setLoadedOrders((prev) => [...prev, ...orders]);
    } else {
      setHasMore(false);
    }
  }, [orders]);

  const handleScroll = (e) => {
    if (e.contentScrollHeight - e.scrollTop === e.clientHeight) {
      if (!loading && !loadingUnreadMessageOrders && hasMore) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handlerElementClick = (order, id) => {
    onOrderSelected(order);
    setSelectedElement(id);
  };

  const handlerClickUnreadMessageFilter = async () => {
    setUnreadMessageFilterChecked(!unreadMessageFilterChecked);
    setLoadedOrders([]);
    if (!unreadMessageFilterChecked) {
      const data = await searchUnreadMessages();
      if (data) {
        setLoadedOrders(data?.data?.searchUnreadMessages?.orders || []);
      }
    } else {
      setLoadedOrders(orders);
    }
  };

  return (
    <div className='self-stretch h-full bg-white flex-col justify-start items-start flex rounded-xl'>
      <div className='w-full bg-white flex-col justify-start items-start gap-5 flex mb-4'>
        <div className='self-stretch justify-start items-start gap-4 inline-flex'>
          <div className='grow shrink basis-0 self-stretch flex-col justify-center items-start gap-1 inline-flex'>
            <div className='self-stretch justify-between items-center gap-2 inline-flex'>
              <div className='flex w-full'>
                <div className='w-[92%]'>
                  <div className='w-full grow shrink basis-0 self-stretch flex-col justify-center items-end gap-3 inline-flex'>
                    <div className='w-full h-11 flex-col justify-start items-start gap-1.5 flex'>
                      <div className='self-stretch px-3.5 py-2.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex'>
                        <div className='grow shrink basis-0 h-6 justify-start items-center gap-2 flex'>
                          <div className="text-gray-500 text-base font-normal font-['Inter'] w-full leading-normal">
                            <SearchBox
                              query={query}
                              onQueryChanged={setQuery}
                              sizeWidth='w-full'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-[8%] text-center flex justify-center'>
                  <UnreadMessageFilterIconComponent
                    unreadMessageFilterChecked={unreadMessageFilterChecked}
                    handlerClickUnreadMessageFilter={
                      handlerClickUnreadMessageFilter
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full h-full bg-white rounded-xl shadow border border-gray-200 flex-col justify-start items-start flex overflow-hidden'>
        <Scrollbar 
          onScroll={handleScroll}
          style={{ width: '100%', height: '100%' }}
          trackYProps={{
            style: {
              width: '8px',
              background: '#f1f1f1',
              borderRadius: '4px',
              marginTop: '0',
              height: '100%',
              padding: 0
            }
          }}
          thumbYProps={{
            style: {
              background: '#c1c1c1',
              borderRadius: '4px'
            }
          }}
        >
        {(loading || loadingUnreadMessageOrders) && (
          <div className='mt-4 ml-4'>
            <div className='float-left'>
              <LoadingIconComponent />
            </div>
          </div>
        )}
        {loadedOrders?.map((order, idx) => {
          return (
            <div
              className='w-full bg-white justify-start items-start inline-flex'
              key={idx}
            >
              <div className='w-full flex-col justify-start items-start inline-flex'>
                <div
                  className={`w-full h-[72px] px-4 py-2 border-b hover:bg-gray-50 border-gray-200 justify-start items-center gap-3 inline-flex cursor-pointer ${
                    selectedElement === idx && 'bg-gray-200'
                  }`}
                  onClick={() => handlerElementClick(order, idx)}
                >
                  <div className='w-[13%] inline-block relative'>
                    <img
                      className='w-[75px] h-14 rounded-[10px]'
                      src={order.item.thumbnail}
                      title={order.item.title}
                    />
                    {order.productInfo.catalog_listing && (
                      <div
                        style={{
                          position: 'absolute',
                          inset: '4px calc(-1 * 10px) auto auto',
                          padding: '0 10px 10px calc(8px + 4px)',
                          clipPath:
                            'polygon(0 0,100% 0,100% calc(100% - 10px), calc(100% - 10px) 100%, calc(100% - 10px) calc(100% - 10px), 0 calc(100% - 10px), 5px calc(50% - 10px/2))',
                          background: '#BD1550',
                          boxShadow: '0 calc(-1 * 10px) 0 inset #0005',
                          color: 'white',
                          fontSize: '7px',
                        }}
                      >
                        Catalogo
                      </div>
                    )}
                    <span className='text-xs bg-gray-500 w-4 text-center text-white rounded absolute top-0 left-0'>
                      {order.quantity}
                    </span>
                  </div>
                  <div className='w-[69%]'>
                    <div
                      className={`text-violet-700 text-sm font-normal font-['Inter'] leading-tight ${
                        order.status === 'cancelled' && 'line-through'
                      }`}
                    >
                      {order.buyer.nickname}
                    </div>
                    <div
                      className={`text-slate-600 text-xs font-normal font-['Inter'] leading-tight ${
                        order.status === 'cancelled' && 'line-through'
                      }`}
                    >
                      {order.item.title}
                    </div>
                  </div>
                  <div className='w-[18%] flex justify-center'>
                    <div>
                      <div className="text-center text-violet-700 text-xs font-medium font-['Inter'] leading-[18px]">
                        Prec: {order.currency_id + '' + order.unit_price}
                      </div>
                      <div className="text-center text-violet-700 text-xs font-medium font-['Inter'] leading-[18px]">
                        Tot: {order.currency_id + '' + order.total_amount}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        </Scrollbar>
      </div>
    </div>
  );
};

export default OrderList;
